<template>
<section class="ol-product ol-product-detail">
    <div class="ol-wrapper ol-wrapper--large">
        <div class="ol-breadcrumb" style="padding-bottom: 30px !important;">
            <SkeletonLoader style="height:30px;width:400px;margin-bottom:10px;" />
        </div>
        <div class="ol-product-content ol-product-content-detail add-fix">
            <div class="ol-product-content-detail__images" style="padding-right: 30px;">
                <div class="ol-product-content-detail__images-list">

                    <div class="ol-product-content-detail__images-list-single">
                        <SkeletonLoader style="height:350px;margin-bottom:10px;" />
                    </div>
                    <div class="ol-product-content-detail__images-list-group">
                        <SkeletonLoader style="height:100px;margin-bottom:10px;" />
                    </div>

                </div>
            </div>
            <div class="ol-product-content-detail__desc">

                <h3 class="ol-home-product__content-item-list--item-title name-product">
                    <SkeletonLoader style="height:35px;margin-bottom:15px;" />
                </h3>
                <div class="ol-home-product__content-item-list--item-price">
                    <span class="ol-home-product__content-item-list--item-price-real" id="harga_asli">
                        <SkeletonLoader style="height:24px;width:150px;margin-bottom:15px;" />
                        <SkeletonLoader style="height:42px;width:250px;margin-bottom:20px;" />
                    </span>

                </div>

                <div class="ol-product-content-detail__desc-spec" style="margin-top:20px;">
                    <SkeletonLoader style="height:79px;margin-bottom:20px;" />
                    <SkeletonLoader style="height:79px;margin-bottom:20px;" />
                    <SkeletonLoader style="height:30px;width:370px;margin-bottom:15px;" />
                    <SkeletonLoader style="height:30px;width:170px;margin-bottom:15px;" />
                    <SkeletonLoader style="height:30px;width:210px;margin-bottom:15px;" />

                </div>

            </div>
        </div>
        <div class="ol-product-content ol-product-content-detail">
            <div class="ol-product-content-detail__list">
                <div id="detail1" class="ol-product-content-detail__list-item ol-product-content-detail__list-item--desc show">
                    <h3 class="ol-product-content-detail__list-item-title">
                        <center> <SkeletonLoader style="height:24px;width:300px;margin-bottom:15px;" /></center>
                        <SkeletonLoader style="height:300px;margin-bottom:15px;" />
                    </h3>
                </div>
            </div>
        </div>
    </div>
</section>
</template>

<script>
import SkeletonLoader from '@/components/loading/skeletonLoader'
export default {
    name:"Detail",
    props:['page','qty'],
    components:{
        SkeletonLoader
    },

}
</script>
