<template>
    <span>
        <div class="review-layout">
            <div class="review-section">
                <div class="reviews">
                    <div v-for="i in qty" :key="'review-loader-'+i" class="review-wrapper">
                        <SkeletonLoader style="height:141px;" />
                    </div>
                </div>
            </div>
        </div>
    </span>
</template>

<script>
import SkeletonLoader from '@/components/loading/skeletonLoader'
export default {
    name:"Review",
    props:['qty'],
    components:{
        SkeletonLoader
    },

}
</script>
