<template>
    <div>
        <div class="review-head-new">
            <div class="row" style="align-items: center; justify-content: center; padding: 12px;">
                <div style="color:gold;">
                    <i class="fas fa-star fa-lg"></i>
                    <i class="fas fa-star fa-lg"></i>
                    <i class="fas fa-star fa-lg"></i>
                    <i class="fas fa-star fa-lg"></i>
                    <i class="fas fa-star fa-lg"></i>
                </div>
                <div class="review-overall-new">
                    <p>{{product.rating}}</p>
                </div>
                <div>
                    <p>({{product.total_reviews ? product.total_reviews : '0'}} ulasan)</p>
                </div>
            </div>
        </div>
        <div>
            <div class="row filteritemrow">
                <div class="filteritemcontainer">
                    <a class="filteritem filteritem_active filteritem-all" @click="toPage(product.id,1)">
                        <span>Semua ({{product.total_reviews ? product.total_reviews : '0'}})</span>
                    </a>
                </div>
                <div  class="filteritemcontainer">
                    <a class="filteritem filteritem-5" @click="toPage(product.id,1,5)">
                        <i class="fas fa-star fa-lg" style="color:gold;"></i> 5 {{product.rating_5 != '0' ? '('+product.rating_5+')' : ''}}
                    </a>
                </div>
                <div  class="filteritemcontainer">
                    <a class="filteritem filteritem-4" @click="toPage(product.id,1,4)">
                        <i class="fas fa-star fa-lg" style="color:gold;"></i> 4 {{product.rating_4 != '0' ? '('+product.rating_4+')' : ''}}
                    </a>
                </div>
                <div  class="filteritemcontainer">
                    <a class="filteritem filteritem-3" @click="toPage(product.id,1,3)">
                        <i class="fas fa-star fa-lg" style="color:gold;"></i> 3 {{product.rating_3 != '0' ? '('+product.rating_3+')' : ''}}
                    </a>
                </div>
                <div  class="filteritemcontainer">
                    <a class="filteritem filteritem-2" @click="toPage(product.id,1,2)">
                        <i class="fas fa-star fa-lg" style="color:gold;"></i> 2  {{product.rating_2 != '0' ? '('+product.rating_2+')' : ''}}
                    </a>
                </div>
                <div  class="filteritemcontainer">
                    <a class="filteritem filteritem-1" @click="toPage(product.id,1,1)">
                        <i class="fas fa-star fa-lg" style="color:gold;"></i> 1  {{product.rating_1 != '0' ? '('+product.rating_1+')' : ''}}
                    </a>
                </div>
            </div>
        </div>
        <div class="break-16"></div>
        <ReviewLoader :qty="3" v-if="loading" />
        <div v-if="!loading" class="review-layout">
            <div class="review-section">
                <div class="reviews">
                    <div v-for="review in reviews" :key="'review-'+review.id" class="review-wrapper">
                        <div class="review-user">
                            <div class="row">
                                <div class="user-profile">
                                    <img v-if="review.r_more_users" :srcset="review.r_more_users.picture ? review.r_more_users.picture : require('../../assets/images/user-profile.jpg')">
                                    
                                    <img v-else :srcset="require('../../assets/images/user-profile.jpg')"></img>
                                    
                                    
                                    
                                </div>
                                <div class="comment-content col">
                                    <div class="row comment-review" style="font-weight: 700;font-size:14px">
                                        <p v-if="review.r_more_users">{{ review.r_more_users.name }}</p>
                                        <p v-else>{{ review.nickname }}</p>
                                    </div>
                                    <div class="row comment-review">
                                        
                                        <div class="starrow" style="color:gold;padding-top: 4px;padding-bottom: 4px;">
                                            <i v-for="star in review.rating" :key="'star-'+review.id+'-'+star" class="fas fa-star"></i>
                                        </div>
                                        <div class="comment-date">
                                            <p>{{review.created_at_desc}}</p>
                                        </div>
                                    </div>
                                    <div class="comment-detail">
                                        <p> 
                                            {{review.description}}<br>
                                            
                                        </p>
                                        <p class="comment-variant">Produk: {{review.r_uni_products.name}}</p>
                                        <div class="row image-review">
                                            <img v-for="image in review.r_more_product_review_images" :key="'review-image-'+image.id" :srcset="image.image">
                                            <!-- <div class="moreimage-review">
                                                <p>+3</p>
                                            </div> -->
                                        </div>
                                    </div>


                                </div>
                            </div>

                        </div>
                        <div class="review-more">
                            <div v-for="reply in review.r_more_product_review_replies" :key="'reply-'+reply.id" class="review-more-wrapper">
                                <h3>Admin MORE</h3>
                                <p>{{reply.text}}</p>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
        <div class="ol-paging"  style="margin-top:0px;margin-bottom:15px;">
            <a @click="toPage(product.id,1,rating_active)" href="javascript:void(0)" class="ol-paging__link review-paging-link review-paging-link-1">&laquo;</a>
            <a @click="toPage(product.id,i,rating_active)" v-for="i in reviewsInfo.total_pages" :key="'page-'+i" href="javascript:void(0)" :class="'ol-paging__link review-paging-link review-paging-link-'+i">{{i}}</a>
            <a @click="toPage(product.id,reviewsInfo.total_pages,rating_active)" href="javascript:void(0)" :class="'ol-paging__link review-paging-link review-paging-link-'+reviewsInfo.total_pages">&raquo;</a>
        </div>
    </div>
</template>

<script>
import store from '@/store'
// import {mapActions,mapGetters} from "vuex";
import ReviewLoader from '@/components/loading/product/Review.vue'
export default {
    name:"ReviewList",
    props:["detail"],
    components:{
        ReviewLoader,
    },
    data(){
        return {
            loading:false,
            rating_active:null,
        }
    },
    computed:{
        product(){
            if(this.detail == "bundle"){
                return store.getters['product/bundle/product']
            }else{
                return store.getters['product/detail/product']
            }
        },
        reviews(){
            if(this.detail == "bundle"){
                return store.getters['product/bundle/reviews']
            }else{
                return store.getters['product/detail/reviews']
            }
        },
        reviewsInfo(){
            if(this.detail == "bundle"){
                return store.getters['product/bundle/reviewsInfo']
            }else{
                return store.getters['product/detail/reviewsInfo']
            }
        },
        
    },
    methods: { 
        async toPage(product_id,page,rating = null){
            $("a.review-paging-link").removeClass("active");
            $("div.filteritemcontainer a.filteritem").removeClass("filteritem_active");
            $("a.review-paging-link-"+page).addClass("active");
            if(rating){
                this.rating_active = rating;
                $("div.filteritemcontainer a.filteritem-"+rating).addClass("filteritem_active");
            }else{
                this.rating_active = null;
                $("div.filteritemcontainer a.filteritem-all").addClass("filteritem_active");
            }
            this.loading = true;
            await store.dispatch("product/detail/fetchReviews",{page:page,product_id:product_id,rating:this.rating_active});
            this.loading = false;
        },
    },

    
}
</script>

<style>

</style>